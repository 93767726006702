select {
  font-size: 14px;
  padding: 8px;
}
label {
  font-size: 16px;
  padding: 4px;
}

.date-picker > div > select {
  color: #333;
  background: #fff;
  width: "100%";
  height: 50px;
  border: #ccc solid 1px;
  margin: 0;
  padding: 8px;
  outline: 0;
  transition: border-color 0.3s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0px;
}

.rsd__react-select-datepicker {
  font-family: "Avenir" sans-serif, monospace;
}
.rsd__select-month {
  font-family: "Avenir" sans-serif, monospace;
  margin-right: 5px;
  border: 1px solid #C0C0C0;
border-radius: 5px;
padding: 1rem;

color: #58595B;

}
.rsd__select-day {
  font-family: "Avenir" sans-serif, monospace;
  margin-right: 5px;
  border: 1px solid #C0C0C0;
border-radius: 5px;
padding: 1rem;
color: #58595B;
}
.rsd__select-year {
  border: 1px solid #C0C0C0;
border-radius: 5px;
padding: 1rem;
color: #58595B;
  font-family: "Avenir" sans-serif, monospace;
}
