

.page{
    width: 100%;
   
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #F3F3F3;
    min-height: min(638px, 80vh);
 padding: 1rem;
}

.container{

  display: flex;
  flex-direction: column;
  gap: 1rem;

  align-items: center;
}


.content {
background: #F8F8F8;
box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.15);
border-radius: 8px;
padding: 3rem 5rem;
width: min(90vw, 1126px);
min-height: 405px;

}

.card {
  border: 1px solid #d3d3d3;
  margin: 5%;
  padding: 2%;
  box-shadow: 7px 10px 12px -5px rgba(0, 0, 0, 0.2);
  overflow: auto;
}

.innerCard {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0%;
  padding-top: 0%;
}
.heading {
   font-family: "Avenir" sans-serif, monospace;
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 36px;
  color: #58595B;
}

.typography {
   font-family: "Avenir" sans-serif, monospace;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #58595B;
}

.bold {
  font-weight: bold;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1%;
}

.link {
  font-family: "Avenir" sans-serif, monospace;
  font-size: 18px;
}

@media only screen and (max-width: 600px) {
  .content {

    padding: 2rem 3rem;

    
    }
}