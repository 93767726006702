
.page{
  width: 100%;
 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #F3F3F3;
  min-height: min(638px, 80vh);
padding: 1rem;
}

.container{

display: flex;
flex-direction: column;
gap: 1rem;

}


.content {
background: #F8F8F8;
box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.15);
border-radius: 8px;
padding: 3rem 5rem;
width: min(90vw, 1126px);
min-height: 405px;
}

h2 {
  font-family: "Avenir" sans-serif, monospace;
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  position: relative;
  margin-top: 1rem;
}
.input {
  margin: 0 0.5rem;
}
.agreeTypography {
  margin-left: 2px;
  font-family: "Avenir", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.00938em;
  color: #58595B;
}

.error {
  color: red;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  bottom: -1.25rem;
}

.termsSpan {
  font-weight: bold;
}

.text {
  font-size: 16px;
  font-family: "Avenir", sans-serif;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.00938em;
  color: #58595B;
}



@media only screen and (max-width: 620px) {
  .content {
  
    padding: 1.5rem 2.5rem; 

    }
}