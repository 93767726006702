.sd-table {
  width: 100%;
  background: $question-background;
  border-collapse: collapse;
  white-space: normal;
}
.sd-table--align-top {
  .sd-table__cell {
    vertical-align: top;
  }
}
.sd-table--alternate-rows {
  margin: 0 8px;
  width: calc(100% - 2 * #{$base-unit});
  .sd-table__cell:first-of-type {
    padding-left: calcSize(2);
  }
  .sd-table__cell:last-of-type {
    padding-right: calcSize(2);
  }
  .sd-table__row:nth-of-type(odd) {
    & > td,
    td:first-of-type {
      background-color: $background-dim-light;
      .sd-input {
        background-color: $primary-foreground;
      }
      .sd-item__decorator {
        --sd-item-default-background: var(--primary-foreground, #fff);
      }
    }
  }
}

.sd-table__cell {
  font-weight: normal;
  font-size: calcFontSize(1);
  line-height: calcSize(3);
  padding: calcSize(1);
  color: $foreground;
  text-align: center;
}
.sd-table__cell--item {
  .sd-selectbase__item {
    text-align: center;
  }

  .sd-selectbase__label {
    justify-content: center;
  }
}
.sd-question--disabled .sd-table__cell {
  opacity: 0.25;
}
.sd-root--readonly .sd-question--disabled .sd-table__cell {
  opacity: 1;
}
.sd-table__cell--header {
  font-weight: 600;
  vertical-align: top;
  padding: calcSize(1.5) calcSize(1) calcSize(2.5);
  &:not(.sd-table__cell--empty) {
    min-width: calcSize(14);
  }
}
.sd-matrixdropdown .sd-table__cell--header.sd-table__cell--empty {
  min-width: calcSize(14);
  width: calcSize(14);
}
.sd-matrixdropdown .sd-table__cell--header:not(.sd-table__cell--empty) {
  min-width: calcSize(14);
  width: calcSize(14);

  &.sd-table__cell--dropdown,
  &.sd-table__cell--rating {
    min-width: calcSize(22);
    width: calcSize(22);
  }
  &.sd-table__cell--boolean {
    min-width: calcSize(18);
    width: calcSize(18);
  }
}
.sd-table__cell--detail-panel {
  padding: 0 calcSize(1) calcSize(1);
}
.sd-table__cell--actions,
.sd-matrixdynamic__add-btn {
  .sv-action-bar {
    overflow: visible;
  }
  .svc-string-editor__button--done {
    width: calcSize(2);
  }
}
.svc-question__content .sd-table__cell--actions {
  .sv-action-bar-item:disabled {
    background: $background;
    opacity: 1;
    use {
      fill: $foreground-light;
    }
  }
}

.sd-table__cell--actions:not(.sd-table__cell--vertical) {
  width: 0;
}

.sd-table__cell--detail-button {
  border: none;
  background: transparent;
  border-radius: calcSize(2);
  width: calcSize(4);
  height: calcSize(4);
  padding: calcSize(1);
  svg {
    width: calcSize(2);
    height: calcSize(2);
    fill: $foreground-light;
  }
  &:hover {
    background: $green-light;
    svg {
      fill: $primary;
    }
  }
}

.sd-table__cell--actions {
  white-space: nowrap;
  &.sd-table__cell--vertical .sd-action-bar {
    justify-content: center;
  }
}
.sd-table__cell--row-text {
  font-weight: 600;
  text-align: left;
  min-width: calcSize(12);
  padding: calcSize(2.5) calcSize(1);
}
.sd-matrixdynamic__content .sd-table__question-wrapper {
  position: relative;
}
.sd-table__question-wrapper:not(:focus-within):hover {
  position: relative;
  .sd-question__erbox--tooltip {
    display: inline-block;
  }
}
.sd-table__cell--actions:not(.sd-table__cell--vertical),
.sd-table__cell--empty,
.sd-table__cell--row-text,
.sd-matrix__cell:first-of-type,
.sd-matrix tr > td:first-of-type {
  position: sticky;
  background: $question-background;
  z-index: 12;
  &:first-of-type {
    left: calcSize(-1);
  }
  &:last-of-type {
    right: calcSize(-1);
  }
}
.sd-table__cell--actions:not(.sd-table__cell--vertical):last-child {
  .sd-action-bar {
    margin-right: calcSize(-3);
    justify-content: flex-end;
    background: $question-background;
  }
}
.sd-question.sd-question--table {
  position: relative;
  overflow-x: auto;
}
.sd-table-wrapper {
  display: flex;
  margin: 0 calc(-1 * var(--sd-base-padding));
  width: fit-content;
  min-width: calc(100% + 2 * var(--sd-base-padding));
  &:before,
  &:after {
    content: "";
    display: block;
    position: sticky;
    min-height: 100%;
    width: calc(var(--sd-base-padding) - #{$base-unit});
    flex-shrink: 0;
    background: $question-background;
    z-index: 11;
  }
  &::before {
    left: calc(-1 * var(--sd-base-padding));
  }
  &:after {
    right: calc(-1 * var(--sd-base-padding));
  }
  & > * {
    flex-basis: 100%;
  }
}
.sd-question--table {
  & > .sd-question__header,
  .sd-question__description--under-input .sv-string-viewer {
    position: sticky;
    left: 0;
  }
  & > .sd-question__content {
    padding-top: calcSize(2.5);
    min-width: min-content;
  }
  &:not(.sd-element--with-frame) {
    padding-right: var(--sd-base-padding);
    margin-right: calc(-1 * var(--sd-base-padding));
    padding-left: var(--sd-base-padding);
    margin-left: calc(-1 * var(--sd-base-padding));
    box-sizing: content-box;
  }
}
.sd-question--scroll {
  overflow-x: scroll;
}
.sd-matrixdropdown.sd-table {
  table-layout: fixed;
}

@mixin table_responsive {
  .sd-question.sd-question--table {
    & > .sd-question__content {
      padding-top: 0;
    }
  }
  .sd-question--table,
  .sd-question--scroll {
    overflow-x: visible;
  }
  .sd-table__cell.sd-matrix__cell {
    display: flex;
    align-items: flex-start;
    .sd-matrix__responsive-title {
      margin-left: calcSize(1);
      text-align: left;
    }
    &:first-of-type {
      padding-top: calcSize(2);
      padding-bottom: calcSize(1);
    }
  }

  .sd-table thead {
    display: none;
  }
  .sd-table {
    tr {
      display: block;
    }
  }
  .sd-matrix__table {
    tr + tr {
      margin-top: calcSize(1);
    }
  }

  .sd-table:not(.sd-matrix__table) {
    tr {
      padding-bottom: calcSize(1);
      &::after {
        z-index: 12;
        content: " ";
        display: block;
        position: relative;
        height: 1px;
        background-color: $border-light;
        left: calcSize(-2);
        bottom: calcSize(-1);
        width: calc(100% + 4 * #{$base-unit});
        z-index: 12;
      }
    }
  }
  .sd-matrix__label {
    justify-content: start;
  }
  .sd-table__cell {
    display: block;
    padding-top: 0;
    padding-bottom: 0;
    text-align: left;
  }

  .sd-table__cell:not(.sd-matrix__cell):not(:first-of-type) {
    margin-top: calcSize(1);
  }
  .sd-table__cell:not(.sd-matrix__cell):not(.sd-table__cell--actions):not(.sd-table__cell--row-text) {
    &::before {
      padding-top: calcSize(2);
      padding-bottom: calcSize(1);
      content: attr(data-responsive-title);
      font-weight: 600;
      display: block;
      text-align: left;
    }
  }
  .sd-table__cell.sd-table__cell--actions {
    width: auto;
    .sd-action-bar {
      margin-right: calcSize(-3);
      justify-content: flex-end;
      background: $background;
    }
  }

  .sd-action.sd-action.sd-matrixdynamic__remove-btn {
    opacity: 1;
    padding: calcSize(1) calcSize(3);
    .sd-action__icon {
      display: none;
    }
    &:after {
      content: attr(title);
    }
  }
  .sd-matrixdynamic__footer {
    padding-top: calcSize(1);
    margin-bottom: calcSize(-2);
  }
  .sd-table__cell--row-text:not(.sd-matrix__cell) {
    color: $foreground-light;
    padding-top: calcSize(2);
  }
  .sd-matrixdropdown.sd-table {
    tr:not(:last-child) {
      padding-bottom: calcSize(2);
    }
    tr::after {
      bottom: calcSize(-2);
    }
    tr:last-child:after {
      content: none;
    }
  }
}
.sd-table__cell--detail-panel {
  .sd-panel__content {
    padding-top: calcSize(1);
  }
}
