
.page{
    width: 100%;
   
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #F3F3F3;
    min-height: min(638px, 80vh);
 padding: 1rem;
}

.container{

  display: flex;
  flex-direction: column;
  gap: 1rem;

}

.form{
    background: #F8F8F8;
box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.15);
border-radius: 8px;
padding: 3rem 2rem;
display: flex;
flex-direction: column;
align-items: center;
gap: 2rem;
justify-content: space-evenly;
min-height: 445px;
 
}

.row{
    display: flex;
    flex-direction: row;
    width: min(750px,90vw);
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
}


.field{
    display: flex;
    flex-direction: column;
    width: 300px;
    flex-shrink: 0;
}
.inputLabel {
    font-size: 14px;
    font-weight: 800;
    color: #161616;
    line-height: 25px;
    font-family: "Avenir" sans-serif, monospace;
 
  }
  


.input {
    font-family: "Avenir" sans-serif, monospace;
    height: 50px;
    color: #c0c0c0;
    border-radius: 5px;
    max-width: 300px;
  }
  
  .errorText {
    color: #ff0000;
    margin-left: 5px;
  }
  
  .span {
    color: #e54142;
  }

  @media only screen and (max-width: 670px) {
    .row{
        display: flex;
       flex-direction: column;
       width: auto;
       gap: 1rem;
    }
  }