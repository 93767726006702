@import "../defaultV2-theme/variables.scss";

$popup-overlay-height: var(--sv-popup-overlay-height, 100vh);

sv-popup {
  display: block;
  position: absolute;
  z-index: -1;
}

.sv-popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  outline: none;
  z-index: 2000;
  height: 100vh;
}

.sv-dropdown-popup {
  height: 0;
}

.sv-popup__container {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  position: absolute;
  padding: 0;
}

.sv-popup__shadow {
  width: 100%;
  height: 100%;
  border-radius: calcCornerRadius(2);
}

.sv-popup__body-content {
  background-color: $background;
  border-radius: calcCornerRadius(1);

  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  max-width: 90vw;
}

.sv-popup.sv-popup--modal {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $background-semitransparent;

  padding: calcSize(11) calcSize(15);
  box-sizing: border-box;

  .sv-popup__container {
    position: static;
  }

  .sv-popup__body-content {
    padding: calcSize(4);
    height: auto;
  }
}

.sv-popup--overlay.sv-popup--overlay {
  width: 100%;
  height: $popup-overlay-height;

  .sv-popup__container {
    background: $background-semitransparent;
    max-width: 100vw;
    max-height: calc(#{$popup-overlay-height} - 1 * #{$base-unit});
    height: calc(#{$popup-overlay-height} - 1 * #{$base-unit});
    width: 100%;
    padding-top: calcSize(2);
    border: unset;
  }

  .sv-popup__body-content {
    max-height: $popup-overlay-height;
    max-width: 100vw;
    border-radius: calcCornerRadius(4) calcCornerRadius(4) 0px 0px;
    background: $background;
    box-shadow: 0px calcSize(1) calcSize(2) rgba(0, 0, 0, 0.1);
    padding: calcSize(3) calcSize(2) calcSize(2);
    height: calc(100% - calc(1 * #{$base-unit}));
  }

  .sv-popup__scrolling-content {
    height: calc(100% - (10 * var(--base-unit, 8px)));
  }

  .sv-popup__body-footer {
    margin-top: calcSize(2);
  }

  .sv-popup__body-footer .sv-action-bar {
    width: 100%;
  }

  .sv-popup__body-footer .sv-action {
    width: 100%;
  }

  .sv-popup__body-footer-item {
    width: 100%;
  }

  .sv-popup__button {
    background-color: $primary;
    border: 2px solid $primary;
    color: $primary-foreground;
  }
}

.sv-popup--modal .sv-popup__scrolling-content {
  padding: 2px;
  margin: -2px;
}

.sv-popup__scrolling-content {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;

  &,
  * {
    &::-webkit-scrollbar {
      height: 6px;
      width: 6px;
      background-color: $background-dim;
    }

    &::-webkit-scrollbar-thumb {
      background: $primary-light;
    }
  }
}

.sv-popup__content {
  min-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.sv-popup--show-pointer.sv-popup--top {
  .sv-popup__pointer {
    transform: translate(calcSize(-1)) rotate(180deg);
  }
}

.sv-popup--show-pointer.sv-popup--bottom {
  .sv-popup__pointer {
    transform: translate(calcSize(-1), calcSize(-1));
  }
}

.sv-popup--show-pointer.sv-popup--right {
  transform: translate(calcSize(1));

  .sv-popup__pointer {
    transform: translate(-12px, -4px) rotate(-90deg);
  }
}

.sv-popup--show-pointer.sv-popup--left {
  transform: translate(calcSize(-1));

  .sv-popup__pointer {
    transform: translate(-4px, -4px) rotate(90deg);
  }
}

.sv-popup__pointer {
  display: block;
  position: absolute;

  &:after {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-left: calcSize(1) solid transparent;
    border-right: calcSize(1) solid transparent;
    border-bottom: calcSize(1) solid $background;
    align-self: center;
  }
}

.sv-popup__body-header {
  font-family: Open Sans;
  font-size: calcFontSize(1.5);
  line-height: calcSize(4);
  font-style: normal;
  font-weight: 700;
  margin-bottom: calcSize(2);
  color: $foreground;
}

.sv-popup__body-footer {
  display: flex;
  margin-top: calcSize(4);
}

.sv-popup__body-footer .sv-action-bar {
  gap: calcSize(1.5);
}

.sv-popup__button {
  padding: calcSize(2) calcSize(6);
  background: $background;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: calcCornerRadius(1);
  margin: 2px;
  cursor: pointer;

  font-family: var(--font-family, $font-family);
  font-style: normal;
  font-weight: 600;
  font-size: calcFontSize(1);
  line-height: calcSize(3);
  text-align: center;
  color: $primary;
  border: none;
  outline: none;
}

.sv-popup__button:hover {
  box-shadow: 0 0 0 2px $primary;
}

.sv-popup__button:disabled {
  color: $foreground;
  opacity: 0.25;
  cursor: default;
}

.sv-popup__button:disabled:hover {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
}

.sv-popup__button--apply {
  background-color: $primary;
  color: $primary-foreground;
}

.sv-popup__button--apply:disabled {
  background-color: $background-dim;
}

.sv-popup--modal,
.sv-popup--overlay {
  .sv-list__filter {
    padding-top: calcSize(1);
  }

  .sv-list__filter-icon {
    top: calcSize(2.5);
  }
}

//styles for dropdown overlay
.sv-dropdown-popup.sv-popup--overlay {
  z-index: 2001;
  padding: 0;

  .sv-popup__body-content {
    padding: 0;
    border-radius: 0;
  }

  .sv-popup__body-footer .sv-action-bar {
    .sv-action {
      width: auto;
    }
  }

  .sv-popup__button {
    background-color: transparent;
    color: $primary;
    border: none;
    box-shadow: none;
    padding: calcSize(1) calcSize(2);
    border-radius: calcSize(12.5);
    margin: 0;
  }

  .sv-popup__container {
    max-height: calc(var(--sv-popup-overlay-height, 100vh));
    height: calc(var(--sv-popup-overlay-height, 100vh));
    padding-top: 0;
  }

  .sv-popup__body-content {
    height: calc(var(--sv-popup-overlay-height, 100vh));
  }

  .sv-popup__body-footer {
    background-color: $background-dim-light;
    margin-top: 0;
    padding-top: calcSize(0.5);
    padding-bottom: calcSize(0.5);
    border-top: 1px solid $border-light;
  }

  .sv-popup__scrolling-content {
    height: calc(100% - 6 * var(--base-unit, 8px));
  }

  .sv-list__filter-icon .sv-svg-icon {
    width: calcSize(2);
    height: calcSize(2);
  }

  .sv-list__container {
    padding: 0;
  }

  .sv-list {
    flex-grow: 1;
    padding: calcSize(0.5);
  }

  .sv-list__filter {
    display: flex;
    align-items: center;
    padding: calcSize(0.5) calcSize(1) calcSize(0.5) calcSize(2.5);
  }

  .sv-list__filter-icon {
    position: static;
    top: calcSize(1.5);
    height: calcSize(2);
  }

  .sv-list__empty-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    padding: calcSize(1) calcSize(0.5);
    background-color: $background;
  }

  .sv-popup__button:disabled {
    pointer-events: none;
    color: $foreground;
    opacity: 0.25;
  }

  .sv-list__filter-clear-button {
    height: calcSize(3);
    width: calcSize(3);
    padding: calcSize(0.5);
    appearance: none;
    border: none;
    border-radius: 100%;
    background-color: transparent;

    svg {
      height: calcSize(2);
      width: calcSize(2);

      use {
        fill: $foreground-light;
      }
    }
  }

  .sv-list__input {
    color: $foreground-light;
    font-size: calcFontSize(1);
    line-height: calcSize(3);
    font-family: $font-family;
    padding: calcSize(0.5) 0 calcSize(0.5) calcSize(1);
  }

  .sv-list__item:hover,
  .sv-list__item:focus,
  .sv-list__item--focused {
    .sv-list__item-body {
      background: $background;
    }

    &.sv-list__item--selected {
      .sv-list__item-body {
        background: $primary;
        color: $primary-foreground;
        font-weight: 600;
      }

      .sd-list__item-body {
        background: $primary-light;
        color: $foreground;
      }
    }
  }
}

.sv-dropdown-popup.sv-popup--overlay.sv-popup--tablet {
  .sv-popup__body-content {
    --sv-popup-overlay-max-height: calc(var(--sv-popup-overlay-height, 100vh) - #{$base-unit} * 8);
    --sv-popup-overlay-max-width: calc(100% - #{$base-unit} * 8);
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    max-height: var(--sv-popup-overlay-max-height);
    min-height: min(var(--sv-popup-overlay-max-height), calcSize(31));
    height: auto;
    width: auto;
    min-width: min(calcSize(40), var(--sv-popup-overlay-max-width));
    max-width: var(--sv-popup-overlay-max-width);
    border-radius: calcCornerRadius(1);
    overflow: hidden;
    margin: 0;
  }

  .sv-popup__content,
  .sv-popup__scrolling-content,
  .sv-list__container {
    flex-grow: 1;
  }
}
