.sd-panel {
  position: relative;
}
.sd-panel.sd-panel--as-page {
  & > .sd-panel__content {
    padding-top: 0;
  }
  & > .sd-panel__header.sd-panel__header {
    padding-top: 0;
    padding-bottom: 0;
    &:after {
      content: none;
    }
    .sd-panel__title {
      @include page_title();
      span {
        font-size: inherit;
        line-height: inherit;
      }
    }
    .sd-panel__description {
      @include page_description();
    }
  }
}
.sd-panel__required-text {
  color: $red;
}
.sd-panel__footer {
  box-sizing: border-box;
  padding-left: calc(var(--sd-base-padding) - 3 * #{$base-unit});
  margin-left: calc(-1 * var(--sd-base-padding));
  width: calc(100% + 2 * var(--sd-base-padding));
  margin-bottom: calc(-1 * var(--sd-base-padding) + 0.5 * var(--sd-base-vertical-padding));
  margin-top: calc(0.5 * var(--sd-base-vertical-padding));
  border-top: 1px solid $border-light;
  padding-top: calc(0.5 * var(--sd-base-vertical-padding));
}
.sd-panel__content {
  padding-top: var(--sd-base-vertical-padding);
}
.svc-question__content {
  .sd-panel__content {
    gap: 0;
    padding-top: 0;
  }
}
